export const FirebaseConfig = {
	"projectId": "vapor-driver",
	"appId": "1:740343820655:web:fa6cdf6d18acd45865f89a",
	"databaseURL": "https://vapor-driver-default-rtdb.firebaseio.com",
	"storageBucket": "vapor-driver.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyB2ZIn2NUXRtUgfS05SsOBB1tJHgpZIPJY",
	"authDomain": "vapor-driver.firebaseapp.com",
	"messagingSenderId": "740343820655",
	"measurementId": "G-6BXT9CLE4H"
};